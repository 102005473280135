import React from 'react';
import './styles.css';

export const Contact = ({
  setisModalOpen
}) => {
  return (
    <div id='contact' className='section'>
      <div className='contact_content'>
        <h1>No job is too <br /> small.</h1>
        <button onClick={() => setisModalOpen(true)} className='contact_button'>Get in touch</button>
      </div>
    </div>);
};

import React from 'react';
import './styles.css';

import paintingIcon from 'assets/painting.png';
import plasteringIcon from 'assets/plastering.png';
import carpentryIcon from 'assets/carpentry.png';
import plumbingIcon from 'assets/plumbing.png';
import flooringIcon from 'assets/flooring.png';
import renderingIcon from 'assets/rendering.png';
import insuranceWorkIcon from 'assets/insurance-work.png';
import tilingIcon from 'assets/tiling.png';
import remedialWorkIcon from 'assets/remedial-work.png';
import cleaningIcon from 'assets/cleaning.png';
import electricalIcon from 'assets/electrical.png';
import waterProofingIcon from 'assets/waterproofing.png';
import generalRepairsIcon from 'assets/general-repairs.png';
import conditionReportsIcon from 'assets/condition-reports.png';

export const Services = () => {
  return (
    <div id='services' className='section'>
      <h1 className='services_title'>Our services</h1>
      <div className='services_container'>
        <div className='service_item'>
          <img src={paintingIcon} alt='Painting icon' />
          Painting
        </div>
        <div className='service_item'>
          <img src={plasteringIcon} alt='Plastering icon' />
          Plastering
        </div>
        <div className='service_item'>
          <img src={carpentryIcon} alt='Carpentry icon' />
          Carpentry
        </div>
        <div className='service_item'>
          <img src={plumbingIcon} alt='Plumbing icon' />
          Plumbing
        </div>
        <div className='service_item'>
          <img src={flooringIcon} alt='Flooring icon' />
          Flooring
        </div>
        <div className='service_item'>
          <img src={renderingIcon} alt='Rendering icon' />
          Rendering
        </div>
        <div className='service_item'>
          <img src={insuranceWorkIcon} alt='Insurance icon' />
          Insurance <br /> work
        </div>
        <div className='service_item'>
          <img src={tilingIcon} alt='Tiling icon' />
          Tiling
        </div>
        <div className='service_item'>
          <img src={remedialWorkIcon} alt='Remedial work icon' />
          Remedial <br /> work
        </div>
        <div className='service_item'>
          <img src={cleaningIcon} alt='Cleaning icon' />
          Cleaning
        </div>
        <div className='service_item'>
          <img src={electricalIcon} alt='Electrical icon' />
          Electrical
        </div>
        <div className='service_item'>
          <img src={waterProofingIcon} alt='Waterproofing icon' />
          Waterproofing
        </div>
        <div className='service_item'>
          <img src={generalRepairsIcon} alt='General repairs icon' />
          General <br /> repairs
        </div>
        <div className='service_item'>
          <img src={conditionReportsIcon} alt='Condition reports icon' />
          Condition <br /> reports
        </div>
      </div>
    </div>
  );
};

import React from 'react';

// import chevrionIcon from 'assets/chevron-forward-outline.svg';

import './styles.css';

export const About: React.FC = () => (
  <div id='about' className='section'>
    <div className='about_content'>
      <div className='about_title_container'>
        <h1 className='about_title'>
          Canberra&apos;s local<br /> maintenance provider
        </h1>
      </div>
      <div className='about_text'>
        <p>
          Our purpose is to provide the highest level of service, quality and
          professionalism without losing the positive characteristics that a
          small business can provide.
        </p>
        <p>
          Our philosophy is to create a relationship and then build on that
          relationship through trust, quality and value for money. This has
          served us well with an ever increasing database of return clients.
        </p>
        <a href='#info' className='more_container'>
          More about us
          {/* <img src={chevrionIcon} alt='Chevron forward icon' /> */}
        </a>
      </div>
    </div>
  </div>
);

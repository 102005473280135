import React from 'react';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';

import { Header } from 'common/ui/header';
import { Footer } from 'common/ui/footer';
import { Home, Privacy } from 'routes';
import { HamburgerMenu } from 'common/ui/hamburger';

import 'common/common-styles.css';

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      < HamburgerMenu />
      <Routes>
        <Route path='/' element={< Home />} />
        < Route path='/privacy-policy' element={< Privacy />} />
        < Route path='/*' element={< Navigate to='/' />} />
      </Routes>
      < Footer />
    </BrowserRouter>
  );
};

export default App;

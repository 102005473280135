import React, { Dispatch, useState } from 'react';
import axios from 'axios';

import './styles.css';

/**
 * IContactFormData
 *
 * @description Contact formData model
 * @property {string} name Contact person name
 * @property {string} emailAddress Contact email address
 * @property {string} message Message
 */
interface IContactFormData {
  name: string;
  emailAddress: string;
  message: string;
}

/**
 * IModal
 *
 * @description Properties for Modal component
 * @property {boolean} isOpen Is modal open boolean
 * @property {Dispatch<boolean>} setIsOpen Set is open method
 */
interface IModal {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Modal: React.FC<IModal> = ({
  isOpen,
  setIsOpen
}) => {
  const [formData, setFormData] = useState<IContactFormData>({
    name: '',
    emailAddress: '',
    message: ''
  });
  const [formSuccess, setFormSuccess] = useState<boolean>(false);

  /**
   * HandleSubmit
   *
   * @description Handle contact form submission
   * @async
   * @returns {Promise<void>} Update form success status no return
   */
  const handleSubmit = async (): Promise<void> => {
    if (!formData.name || !formData.message || !formData.emailAddress) {
      return;
    }

    const response = await axios({
      method: 'post',
      url: `https://${process.env.REACT_APP_BASE_URL}/contact.php`,
      headers: { 'content-type': 'application/json' },
      data: formData
    });

    if (response.status === 200) {
      setFormSuccess(true);
    }
  };

  /**
   * HandleOnInputChange
   *
   * @description Handle contact form input onChange event
   * @param {'name' | 'emailAddress' | 'message'} key FormData object key
   * @param {string} value Input field value
   */
  const handleOnInputChange = (key: 'name' | 'emailAddress' | 'message', value: string): void => {
    const updatedFormData = {
      ...formData,
      [key]: value
    };

    setFormData(updatedFormData);
  };

  return (
    <div className={`modal_bg ${isOpen ? 'active' : ''}`}>
      <div className='modal'>
        {
          formSuccess
            ?
            <div className='contact__successContainer'>
              <button onClick={() => setIsOpen(false)} className='cancel_modal_button'></button>
              <h2>Thanks for getting in touch with us we will get back to you shortly</h2>
            </div>
            :
            <>
              <h2>Get in touch with us</h2>
              <form className='contact_form'>
                <label htmlFor='name'>Name: </label>
                <input
                  type='text'
                  name='name'
                  placeholder='David Lee'
                  required
                  onChange={e => handleOnInputChange('name', e.currentTarget.value)}
                />

                <label htmlFor='email'>Email: </label>
                <input
                  type='email'
                  name='emailAddress'
                  placeholder='example@domain.com.au'
                  required
                  onChange={e => handleOnInputChange('emailAddress', e.currentTarget.value)}
                />

                <label htmlFor='message'>Message: </label>
                <textarea
                  name='message'
                  id='message'
                  rows={10}
                  placeholder='Let us know how we can help and we will get in touch'
                  required
                  onChange={e => handleOnInputChange('message', e.currentTarget.value)}
                />

                <button type='button' className='send_modal_button' onClick={handleSubmit}>Send</button>
              </form>
              <button onClick={() => setIsOpen(false)} className='cancel_modal_button'></button>
            </>
        }
      </div>
    </div>
  );
};

import React from 'react';

import 'common/common-styles.css';

import { Title } from './Title';
import { About } from './About';
import { Services } from './Services';
import { Info } from './Info';
import { Contact } from './Contact';
import { useState } from 'react';
import { Modal } from './Modal';

export const Home = () => {
  const [isModalOpen, setisModalOpen] = useState(false);

  return (
    <>
      <Title />
      <About />
      <Services />
      <Info />
      <Contact setisModalOpen={setisModalOpen} />
      <Modal isOpen={isModalOpen} setIsOpen={setisModalOpen} />
    </>
  );
};

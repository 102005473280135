import React, { useState } from 'react';
import './styles.css';

import cpamgBar from 'assets/cpamg-bar.png';

export const HamburgerMenu = () => {
  const [isOpen, setisOpen] = useState(false);
  const toggleHamburgerMenu = () => setisOpen(!isOpen);

  return (
    <>
      <div className={`hamburger ${isOpen ? 'active' : ''}`} onClick={toggleHamburgerMenu}>
        <span className='bar' />
        <span className='bar' />
        <span className='bar' />
      </div>

      <div className={`hamburger-menu ${isOpen ? 'active' : ''}`}>
        <hr />
        <nav className='hamburger-nav'>
          <a href='#home' onClick={toggleHamburgerMenu}>home</a>
          <a href='#about' onClick={toggleHamburgerMenu}>about</a>
          <a href='#services' onClick={toggleHamburgerMenu}>services</a>
          <a href='#contact' onClick={toggleHamburgerMenu}>
            get in touch
          </a>
        </nav>
        <img className={`cpamg-graphic ${isOpen ? 'active' : ''}`} src={cpamgBar}></img>
        <footer className='hamburger-footer'>
          Canberra Property and Maintenance Group
          <div className='hamburger-social-container'>
            <a href='http://www.facebook.com' target='_blank' rel='noopener noreferrer' className='icon_link'>
              <svg xmlns='http://www.w3.org/2000/svg' className='ionicon' viewBox='0 0 512 512' height='25px' width='25px'
                fill='currentColor'>
                <title>Logo Facebook</title>
                <path
                  d='M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z'
                  fillRule='evenodd' />
              </svg>
            </a>
            <a href='http://www.instagram.com' target='_blank' rel='noopener noreferrer' className='icon_link'>
              <svg xmlns='http://www.w3.org/2000/svg' className='ionicon' viewBox='0 0 512 512' height='25px' width='25px'
                fill='currentColor'>
                <title>Logo Instagram</title>
                <path
                  d='M349.33 69.33a93.62 93.62 0 0193.34 93.34v186.66a93.62 93.62 0 01-93.34 93.34H162.67a93.62 93.62 0 01-93.34-93.34V162.67a93.62 93.62 0 0193.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z' />
                <path
                  d='M377.33 162.67a28 28 0 1128-28 27.94 27.94 0 01-28 28zM256 181.33A74.67 74.67 0 11181.33 256 74.75 74.75 0 01256 181.33m0-37.33a112 112 0 10112 112 112 112 0 00-112-112z' />
              </svg>
            </a>
          </div>
        </footer>
      </div>
    </>
  );
};

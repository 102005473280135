import React, { useState } from 'react';

import './styles.css';

const content = {
  'government': <>We value our local, state and federal government clients, all work is completed with professionalism and discretion at all times. <br /><br /> Our team have the necessary skills and experience to deliver any size project from simple cleaning jobs to more complex and time sensitive repairs.</>,
  'strata': <>We assist busy strata managers in facilitating small and large maintenance requests for properties of all sizes. <br /><br /> Our maintenance and management expertise ensures all work runs smoothly and is completed to a high standard with warranties provided for all completed work.</>,
  'commercial': <>We specialise in small to medium commercial projects. From a shop fit out to a commercial renovation, we can assist with everything from painting and plaster to commercial grade wallpapers to achieve your desired look and style.</>,
  'residential': <>Our residential clients benefit from our extensive experience and customer service. <br /><br /> From cleaning a driveway to painting your entire home and everything in between, we deliver cost effective results to even the fussiest of clients.</>,
  'insurance': <>If your home or investment property has been damaged, we can help get it back to original condition with our expert team of trades. We treat your home and property with respect and complete all work in a timely fashion. <br /><br /> All our tradesmen have appropriate qualifications, recent police checks and local ACT/NSW OHS documentation.</>
};

export const Info = () => {
  const [activeContent, setactiveContent] = useState('government');
  const changeInfoContent = (id) => setactiveContent(id);

  return (
    <div id='info' className='section'>
      <div className='info_container'>
        <div className='info_controls'>
          <h1>Our core partners</h1>
          <button id='government' className='active' onClick={(event) => changeInfoContent(event.target.id)}>Government</button>
          <button id='insurance' onClick={(event) => changeInfoContent(event.target.id)}>Insurance</button>
          <button id='commercial' onClick={(event) => changeInfoContent(event.target.id)}>Commercial</button>
          <button id='residential' onClick={(event) => changeInfoContent(event.target.id)}>Residential</button>
          <button id='strata' onClick={(event) => changeInfoContent(event.target.id)}>Body Corporate/Strata</button>
          <select onChange={(event) => changeInfoContent(event.target.value)}>
            <option value='government'>Government</option>
            <option value='insurance'>Insurance</option>
            <option value='commercial'>Commercial</option>
            <option value='residential'>Residential</option>
            <option value='strata'>Body Corporate/Strata</option>
          </select>
        </div>
        <div className='info_content'>
          <div className='info_content__inner'>
            <h1 className='info_content_header'>{activeContent}</h1>
            <p className='info_content_text'>
              {content[activeContent]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
